$(document).ready(function() {

	var dirRtlFlag = false;
	if ($("html").attr('dir') == 'rtl') {
		dirRtlFlag = true;
	}

	$('.cat-nav__slider').slick({
		dots: false,
		infinite: true,
		speed: 2000,
		slidesToShow: 5,
		slidesToScroll: 1,
		arrows: true,
		autoplay: true,
		autoplaySpeed: 2000,
		rtl: dirRtlFlag,
		responsive: [
			{
				breakpoint: 1200,
				settings: {
					slidesToShow: 5,
					slidesToScroll: 1
				}
			},
			{
				breakpoint: 993,
				settings: {
					slidesToShow: 4,
					slidesToScroll: 1
				}
			},
			{
				breakpoint: 840,
				settings: {
					slidesToShow: 4,
					slidesToScroll: 1
				}
			},
			{
				breakpoint: 690,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 1
				}
			},
			{
				breakpoint: 515,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1
				}
			},
			// {
			// 	breakpoint: 340,
			// 	settings: {
			// 		slidesToShow: 1,
			// 		slidesToScroll: 1
			// 	}
			// }
		]
	});

})